@include breakpoint(desktop){

}
@include breakpoint(biglaptop) {
  body{
    background-color: $color_1;
  }
}
@include breakpoint(laptop){
  body{
    background-color: $color_2;
  }
}
@include breakpoint(tablet) {
  body{
    background-color: $color_3;
  }

}
@include breakpoint(phablet) {
  body{
    background-color: $color_4;
  }

}
@include breakpoint(mobileonly) {
  body{
    background-color: $color_5;
  }
  header {
    .logo{
      max-width: 23%;
      left: 21%;
    }
  }

  header {
    .col{
      display: inline-block;
      text-align: right;
      padding: 0.5rem 1rem;
      .social, .icons-header, form{
        padding: 0.55rem 0;
      }
      /* form{
        margin-bottom: 1rem;
      } */
      .social{
        a{
          img{
            height: 1.5rem;
          }
        }
      }

      .icons-header{
        justify-content: flex-end;
        .icon-header{
          margin-left: 0.2rem;
        }
        img{
          width: auto;
          height: 1.5rem;
        }
      }
    }
    form{
      width: 100%;
      margin: 0;
      /* margin-left: 10%; */
      input[type="search"]{
        font-size: 0.6rem;
      }
      input[type="image"]{
        height: 1rem;
      }
    }
  }

  .menue {
    display: none;
  }
  /* .menu-mobile {
    display: block !important;
  } */
  .menue {
    width: 100% !important;
    padding: 10px !important;
    box-sizing: border-box;
    position: relative;
    top: 100px;
    background: white;
    a {
      width: 100%;
      display: block;
      margin: 0 !important;
      box-sizing: border-box;
      color: $principal !important;
      border-bottom: 1px solid $principal;
    }
  }
  .ancla-y-viento{
    header {
      .icons-header {
        .icon-header {
          & > img{
            width: 2rem;
          }
          & > a {
            img{  
              width: 2rem;
            }
            .badge{
              width: 1rem;
              height: 1rem;
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
  .menu-filtro{
    ul{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
      display: none;
      gap: 0.5rem 0;
      li{
        display: inline-block;
        text-align: center;
        a{
          width: 100%;
          font-size: 0.8rem;
          display: inline-block;
          padding: 0.2rem 0.5rem;
        }
      }
    }
  }
}
@include breakpoint(mobileland){
  body{
    background-color: $color_6;
  }
}
