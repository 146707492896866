@font-face {
  font-family: 'Futura Hv BT';
  src: url('../fonts/FuturaBT-Heavy.woff2') format('woff2'),
      url('../fonts/FuturaBT-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap; 
}

@font-face {
  font-family: 'Futura Md BT';
  src: url('../fonts/FuturaBT-Medium.woff2') format('woff2'),
      url('../fonts/FuturaBT-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Lt BT';
  src: url('../fonts/FuturaBT-Light.woff2') format('woff2'),
      url('../fonts/FuturaBT-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

body{
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  /* font-family: $font_family_cuerpo; */
}

h1, h2, h3, h4, h5, h6{
  /* font-family: $font_family_titulos; */
  /* font-weight: bold;
  letter-spacing: 7px; */
}

p, label, input, a{
  font-family: $font_family_cuerpo;
  color: $gris;
}

button, .btn{
  font-family: $font_family_titulos;
  font-weight: bold;
  text-align: center;
  font-size: 0.8vw;
  padding: 14px;
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
  letter-spacing: 5px;
  display: inline-block;
  background-color: $principal;
  color: white;
  width: 100%;
  cursor: pointer;
}
a {
  text-decoration: none;
}
.clearfix {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
a.btn, .a_btn{
  border: solid 1px $principal;
  background-color: white;
  color: $principal;
  text-decoration: none;
  width: calc( 100% - 28px);
  /*min-width: 272px;*/
}

.form-control{
  border: solid 1px $principal;
  color: $gris;
  padding: 3px 15px;
  width: calc( 100% - 30px);
  border-radius: 12px;
  height: 40px;
  margin-bottom: 21px;
  font-size: 1.0vw;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*Loader 1- Spinning */


#loader-1 #loader{
  position: relative;
  left: 50%;
  top: 50%;
  height: 20vw;
  width: 20vw;
  margin: -10vw 0 0 -10vw;
  border: 3px solid transparent;
  border-top-color: $principal;
  border-bottom-color: $secundario;
  border-radius: 50%;
  z-index: 2;
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  -o-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

#loader-1 #loader:before{
  content: "";
  position: absolute;
  top:2%;
  bottom: 2%;
  left: 2%;
  right: 2%;
  border: 3px solid transparent;
  z-index: 2;
  border-top-color: $principal;
  border-radius: 50%;
  -webkit-animation: spin 3s linear infinite;
  -moz-animation: spin 3s linear infinite;
  -o-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;

}

#loader-1 #loader:after{
  content: "";
  position: absolute;
  top:5%;
  bottom: 5%;
  left: 5%;
  right: 5%;
  border: 3px solid transparent;
  border-top-color: $secundario;
  z-index: 2;
  border-radius: 50%;
  -webkit-animation: spin 1.5s linear infinite;
  -moz-animation: spin 1.5s linear infinite;
  -o-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;

}




/*Keyframes for spin animation */

@-webkit-keyframes spin {
  0%   {
    -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);  /* IE 9 */
    transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
  }

  50% {
    -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);  /* IE 9 */
    transform: rotate(180deg);  /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);  /* IE 9 */
    transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
  }
}


@-moz-keyframes spin {
  0%   {
    -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);  /* IE 9 */
    transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
  }

  50% {
    -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);  /* IE 9 */
    transform: rotate(180deg);  /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);  /* IE 9 */
    transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
  }
}

@-o-keyframes spin {
  0%   {
    -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);  /* IE 9 */
    transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
  }

  50% {
    -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);  /* IE 9 */
    transform: rotate(180deg);  /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);  /* IE 9 */
    transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
  }
}

@keyframes spin {
  0%   {
    -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);  /* IE 9 */
    transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
  }

  50% {
    -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);  /* IE 9 */
    transform: rotate(180deg);  /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);  /* IE 9 */
    transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
  }
}


h2{
  font-family: 'Futura Hv BT';
  font-weight: bold;
  letter-spacing: 0;
  font-size: 1.7vw;
  margin: 2vw 0;  
}


.text-left{
  text-align: left !important;
} 

.text-center{
  text-align: center !important;
}

.text-uppercase{
  text-transform: uppercase;
}


.color-principal{
  color: $principal !important;
}

.bg-principal{
  background-color: $principal;
}

.menu-filter-ico{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ebebeb;
  padding: 1.5vw 0 3vw 0;
  .item{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5vw 1vw;
    img{
      height: 2vw;
      margin-right: 1vw;
    }
    span{
      color: $principal;
      font-family: 'Futura Hv BT';
      font-size: 1vw;
    }
  }

}  

.cats{
  display: inline-block;    
  width: 100%;
  vertical-align: top;
  text-align: center;
  margin-top: 5vw;
  li{
    width: 7vw;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    a{
      width: 5vw;
      height: 5vw;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      @include border-radius(50%);
      &:hover{
        img{
          transform: scale(1.1);
        }
      }
      img{
        display: inline-block;
        /* width: 100%; */
        min-width: 100%;
        min-height: 100%;
        /* @include border-radius(50%); */
        background-color: $gris;
        transition: all .2s ease-in-out;
        &.active, &:hover{
          background-color: $principal;
        }
        
      }
    }
    span{
      transition: all .2s ease-in-out;
      display: inline-block;
      width: 100%;
      text-align: center;
      color: $principal;
      font-family: 'Futura Md BT';
      text-decoration: none;
      font-weight: bold;
      font-size: 0.8vw;
      margin-top: 0.5vw;
    }
    &.active{
      a{
        img{
          background-color: $principal;
          transform: scale(1.1);
        }
      }
    }
  }
}

.grid-productos-int{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;  
  gap: 1vw;
}


.producto{
  width: calc(100% - 4px);  
  height: 100%;
  border: 1px solid $principal;
  position: relative;
  .wp{
    padding: 0.5vw;
  }
  .img{
    height: 10vw;  
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      max-width: 90%;
      max-height: 90%;
    }
  }
  .swiper-container{
    height: 10vw;  
    display: flex;
    justify-content: center;
    align-items: center;

    .swiper-slide{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    img{
      max-width: 90%;
      max-height: 90%;
    }
  }

  h4{
    text-align: center;
    font-family: 'Futura Md BT';
    letter-spacing: inherit;
    color: $principal;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: 1.6vw;
    margin: 0 auto 1vw;
    font-size: 0.8vw;
    line-height: 1;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h3{
    margin: 0; 
    color: $principal;
    opacity: 0.5;
    font-family: 'Futura Md BT';
    letter-spacing: inherit;
    font-size: 1.5vw;
    min-height: 1.5vw;
    /* margin-bottom: 0.5vw; */
  }
  .precio{
    text-align: center;
    font-size: 1vw;
    font-family: 'Futura Md BT';
    color: $principal;
    margin-top: 1vw;
  }
  .count{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vw 0;
    span{
      padding: 0.5vw;
      font-size: 1vw;
      display: inline-block;
    }
    .cont_circ{ 
      img{
        height: 1.4vw;
      }
    }
  }
  
  .btn{
    background-color: $principal;
    color: white;
    font-family: 'Futura Md BT';
    font-size: 0.8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5vw;
    img{
      height: 0.8vw;
      margin-right: 0.5vw;
    }
  }

  .agotado{
    color: red;
  }
}


.prodInner{
    
  display: flex;
  width: 100%;
  padding: 15vh 0;
  align-items: stretch;
  justify-content: center;
  .img{
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
          max-width: 100%;
          max-height: 100%;
      }
  }
  .textos{
      width: 50%;
      text-align: left;
      padding: 0 20px;
      h2, h3{
        text-align: left;
      }
  }
  h2{
      font-size: 3vmin;
      font-family: 'Futura Hv BT';
      color: $principal;
  }
  h3{
      /* margin: 15px; */
      font-size: 3vmin;
      font-family: 'Futura Hv BT';
      color: $principal;
  }
  ul{
      width: 100%;
      /* text-align: center; */
  }
  p{
      width: 100%;
      
      margin: 10px 0;
  }
  input{
      margin: 20px;
      padding: 15px;
  }
  .btn_3{
      display: inline-block;
      margin: 20px 0;
      border-radius: 8px;
      //width: 100% !important;
      padding: 15px 30px;
      font-weight: bold;
      text-transform: uppercase;
      background-color: $principal;
      color: white;
      font-size: 2vmin;

  }
  .stats{
    width: 100%;
  }
  .swiper-container{
    width: 50%;
    .swiper-slide{
      img{
        width: 100%;
      }
    }
    .swiper-button-next, .swiper-button-prev{
      color: $principal;
    }
  }
}


@import "breakpoints_general.scss";
