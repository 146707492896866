@include breakpoint(desktop){

}
@include breakpoint(biglaptop) {
  body{
    background-color: $color_1;
  }
  .wrap_page{
    width: 80%;
    .cont_tiendas{
      .tienda{
        width: 23%;
        margin: 15px 1%;
        .list-content{
          h4{
            font-size: 1.4vw;
          }
          h2{
            font-size: 1.2vw;
          }
          .opts{
            p{
              font-size: 1.2vw;
            }
            .cart_opt{
              span{
                font-size: 1.1vw;
              }
              .cont_circ {
                width: 14%;
              }
            }
          }
        }
      }
    }
    .cats{
      li{
        a{
          span{
            font-size: 1.2vw;
          }
        }
      }
    }
  }
}
@include breakpoint(laptop){
  body{
    background-color: $color_2;
  }

  .wrap_page{
    width: 85%;
    h1{
      font-size: 2.3vw;
    }
    .buscador{
      .form-control{
        font-size: 1.7vw;
      }
    }
    .cont_tiendas{
      .tienda{
        .list-content{
          h2{
            font-size: 1.5vw;
          }
          a{
            font-size: 1.1vw;
            padding: 9px 0;
          }
        }
      }
    }
    .cats{
      li{
        a{
          span{
            font-size: 1.2vw;
          }
        }
      }
    }
  }
}
@include breakpoint(tablet) {
  body{
    background-color: $color_3;
  }
  .full_banner{
    .mitad{
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .wrap_page{
    width: 90%;
    h2{
      font-size: 2vw;
    }
    h3{
      font-size: 1.8vw;
    }
    .cont_tiendas{
      .tienda{
        .list-content{
          .cont_img{
            img{
              max-height: 200px;
              width: auto;
            }
          }
          h4{
            font-size: 1.8vw;
          }
          h2{
          }
          .opts{
            p{
              font-size: 1.5vw;
            }
            .cart_opt{
              span{
                font-size: 1.5vw;
              }
              .cont_circ {
              }
            }
          }
        }
      }
    }
    .cats{
      li{
        a{
          span{
            font-size: 1.4vw;
          }
        }
      }
    }
  }

}
@include breakpoint(phablet) {
  body{
    background-color: $color_4;
  }
  .wrap_page{
    .cats{
      li, li.ancla-y-viento{
        width: 30%;
        a{
          span{
            font-size: 2.4vw;
          }
        }
      }
    }
    h1{
      font-size: 2.9vw;
    }
    .buscador{
      .form-control{
        font-size: 2.3vw;
      }
    }
    .cont_tiendas{
      .tienda{
        width: 48%;
        .list-content{
          h2{
            font-size: 2.4vw;
          }
          h4{
            font-size: 2.4vw;
          }
          a{
            font-size: 1.8vw;
            padding: 16px 0;
          }
          .opts{
            p{
              font-size: 2vw;
            }
            .cart_opt{
              span{
                font-size: 2vw;
              }
              .cont_circ {
              }
            }
          }
        }
      }
    }
  }

}
@include breakpoint(mobileonly) {
  body{
    background-color: $color_5;
  }
  .ancla-y-viento .full_banner .mitad:nth-of-type(1),.ancla-y-viento .full_banner .mitad:nth-of-type(2) {
    width: 100%;
  }
  .full_banner{
    .mitad{
      h1{
        font-size: 6vw;
      }
    }
  }

  .wrap_page{
    width: 95%;
    .cats{
      margin-top: 0;
      li, li.ancla-y-viento{
        width: 47%;
        a{
          span{
            font-size: 2.8vw;
          }
        }
      }
    }
    width: 90%;
    h1{
      font-size: 5.2vw;
    }
    h2{
      font-size: 4vw;
    }
    h3{
      font-size: 3.6vw;
    }
    .buscador{
      width: 70%;
      .form-control{
        font-size: 3.4vw;
      }
    }
    .cont_tiendas{
      .tienda{
        width: 100%;
        margin: 15px 0;
        .list-content{
          h4{
            font-size: 5.2vw;
          }
          h2{
            font-size: 3.2vw;
          }
          a{
            font-size: 2.8vw;
            padding: 19px 0;
          }
          .opts{
            p {
              font-size: 5vw;
            }
            .cart_opt span {
              font-size: 4vw;
            }
          }
        }
      }
    }
  }
  .ancla-y-viento .wrap_page .cont_tiendas .tienda .list-content .opts p {
    font-size: 4vw;
  }

  .ancla-y-viento {
    .wrap_page {
      .cont_tiendas {
        .tienda {
          .list-content {
            padding: 1rem;
            .cont_img {
              height: 30vh;
            }
            a{
             font-size: 1rem;   
            }
          }
        }
      }
    }
  }

  

  .deco-f{
    display: none;
  }

  h2{
    margin: 1.2rem 0;
  }

  .grid-productos {
    .swiper-button-next, .swiper-button-prev{
      &::after{
        font-size: 1.5rem;
      }
    }
    .swiper-button-next{
      left: inherit;
      right: 0;
    }
    .swiper-button-prev{
      left: 0;
      right: inherit;
    }
  }

  

}
@include breakpoint(mobileland){
  body{
    background-color: $color_6;
  }

}
