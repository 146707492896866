/*
$principal : #3c647e;
$secundario: #fdfbd2;
$terciario: #fdfbd2;
$cuartario: #dc843c;
$gris: #6b6b6b;
$gris_claro: #c4c4c4;
$gris_claro_textos: #909090;
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: 'Futura Hv BT';
  src: url("../fonts/FuturaBT-Heavy.woff2") format("woff2"), url("../fonts/FuturaBT-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Md BT';
  src: url("../fonts/FuturaBT-Medium.woff2") format("woff2"), url("../fonts/FuturaBT-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Lt BT';
  src: url("../fonts/FuturaBT-Light.woff2") format("woff2"), url("../fonts/FuturaBT-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  /* font-family: $font_family_cuerpo; */
}

h1, h2, h3, h4, h5, h6 {
  /* font-family: $font_family_titulos; */
  /* font-weight: bold;
  letter-spacing: 7px; */
}

p, label, input, a {
  font-family: "Roboto", sans-serif;
  color: #6b6b6b;
}

button, .btn {
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: 0.8vw;
  padding: 14px;
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
  letter-spacing: 5px;
  display: inline-block;
  background-color: #001e60;
  color: white;
  width: 100%;
  cursor: pointer;
}

a {
  text-decoration: none;
}

.clearfix::after {
  display: block;
  content: "";
  clear: both;
}

a.btn, .a_btn {
  border: solid 1px #001e60;
  background-color: white;
  color: #001e60;
  text-decoration: none;
  width: calc( 100% - 28px);
  /*min-width: 272px;*/
}

.form-control {
  border: solid 1px #001e60;
  color: #6b6b6b;
  padding: 3px 15px;
  width: calc( 100% - 30px);
  border-radius: 12px;
  height: 40px;
  margin-bottom: 21px;
  font-size: 1.0vw;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*Loader 1- Spinning */
#loader-1 #loader {
  position: relative;
  left: 50%;
  top: 50%;
  height: 20vw;
  width: 20vw;
  margin: -10vw 0 0 -10vw;
  border: 3px solid transparent;
  border-top-color: #001e60;
  border-bottom-color: #333333;
  border-radius: 50%;
  z-index: 2;
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  -o-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

#loader-1 #loader:before {
  content: "";
  position: absolute;
  top: 2%;
  bottom: 2%;
  left: 2%;
  right: 2%;
  border: 3px solid transparent;
  z-index: 2;
  border-top-color: #001e60;
  border-radius: 50%;
  -webkit-animation: spin 3s linear infinite;
  -moz-animation: spin 3s linear infinite;
  -o-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

#loader-1 #loader:after {
  content: "";
  position: absolute;
  top: 5%;
  bottom: 5%;
  left: 5%;
  right: 5%;
  border: 3px solid transparent;
  border-top-color: #333333;
  z-index: 2;
  border-radius: 50%;
  -webkit-animation: spin 1.5s linear infinite;
  -moz-animation: spin 1.5s linear infinite;
  -o-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

/*Keyframes for spin animation */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);
    /* IE 9 */
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */
  }
  50% {
    -webkit-transform: rotate(360deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);
    /* IE 9 */
    transform: rotate(180deg);
    /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);
    /* IE 9 */
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */
  }
}

@-moz-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);
    /* IE 9 */
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */
  }
  50% {
    -webkit-transform: rotate(360deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);
    /* IE 9 */
    transform: rotate(180deg);
    /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);
    /* IE 9 */
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */
  }
}

@-o-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);
    /* IE 9 */
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */
  }
  50% {
    -webkit-transform: rotate(360deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);
    /* IE 9 */
    transform: rotate(180deg);
    /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);
    /* IE 9 */
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);
    /* IE 9 */
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */
  }
  50% {
    -webkit-transform: rotate(360deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);
    /* IE 9 */
    transform: rotate(180deg);
    /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);
    /* IE 9 */
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */
  }
}

h2 {
  font-family: 'Futura Hv BT';
  font-weight: bold;
  letter-spacing: 0;
  font-size: 1.7vw;
  margin: 2vw 0;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.color-principal {
  color: #001e60 !important;
}

.bg-principal {
  background-color: #001e60;
}

.menu-filter-ico {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ebebeb;
  padding: 1.5vw 0 3vw 0;
}

.menu-filter-ico .item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5vw 1vw;
}

.menu-filter-ico .item img {
  height: 2vw;
  margin-right: 1vw;
}

.menu-filter-ico .item span {
  color: #001e60;
  font-family: 'Futura Hv BT';
  font-size: 1vw;
}

.cats {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  text-align: center;
  margin-top: 5vw;
}

.cats li {
  width: 7vw;
  display: inline-block;
  text-align: center;
  vertical-align: top;
}

.cats li a {
  width: 5vw;
  height: 5vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

.cats li a:hover img {
  transform: scale(1.1);
}

.cats li a img {
  display: inline-block;
  /* width: 100%; */
  min-width: 100%;
  min-height: 100%;
  /* @include border-radius(50%); */
  background-color: #6b6b6b;
  transition: all .2s ease-in-out;
}

.cats li a img.active, .cats li a img:hover {
  background-color: #001e60;
}

.cats li span {
  transition: all .2s ease-in-out;
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #001e60;
  font-family: 'Futura Md BT';
  text-decoration: none;
  font-weight: bold;
  font-size: 0.8vw;
  margin-top: 0.5vw;
}

.cats li.active a img {
  background-color: #001e60;
  transform: scale(1.1);
}

.grid-productos-int {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 1vw;
}

.producto {
  width: calc(100% - 4px);
  height: 100%;
  border: 1px solid #001e60;
  position: relative;
}

.producto .wp {
  padding: 0.5vw;
}

.producto .img {
  height: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.producto .img img {
  max-width: 90%;
  max-height: 90%;
}

.producto .swiper-container {
  height: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.producto .swiper-container .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.producto .swiper-container img {
  max-width: 90%;
  max-height: 90%;
}

.producto h4 {
  text-align: center;
  font-family: 'Futura Md BT';
  letter-spacing: inherit;
  color: #001e60;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 1.6vw;
  margin: 0 auto 1vw;
  font-size: 0.8vw;
  line-height: 1;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.producto h3 {
  margin: 0;
  color: #001e60;
  opacity: 0.5;
  font-family: 'Futura Md BT';
  letter-spacing: inherit;
  font-size: 1.5vw;
  min-height: 1.5vw;
  /* margin-bottom: 0.5vw; */
}

.producto .precio {
  text-align: center;
  font-size: 1vw;
  font-family: 'Futura Md BT';
  color: #001e60;
  margin-top: 1vw;
}

.producto .count {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1vw 0;
}

.producto .count span {
  padding: 0.5vw;
  font-size: 1vw;
  display: inline-block;
}

.producto .count .cont_circ img {
  height: 1.4vw;
}

.producto .btn {
  background-color: #001e60;
  color: white;
  font-family: 'Futura Md BT';
  font-size: 0.8vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5vw;
}

.producto .btn img {
  height: 0.8vw;
  margin-right: 0.5vw;
}

.producto .agotado {
  color: red;
}

.prodInner {
  display: flex;
  width: 100%;
  padding: 15vh 0;
  align-items: stretch;
  justify-content: center;
}

.prodInner .img {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prodInner .img img {
  max-width: 100%;
  max-height: 100%;
}

.prodInner .textos {
  width: 50%;
  text-align: left;
  padding: 0 20px;
}

.prodInner .textos h2, .prodInner .textos h3 {
  text-align: left;
}

.prodInner h2 {
  font-size: 3vmin;
  font-family: 'Futura Hv BT';
  color: #001e60;
}

.prodInner h3 {
  /* margin: 15px; */
  font-size: 3vmin;
  font-family: 'Futura Hv BT';
  color: #001e60;
}

.prodInner ul {
  width: 100%;
  /* text-align: center; */
}

.prodInner p {
  width: 100%;
  margin: 10px 0;
}

.prodInner input {
  margin: 20px;
  padding: 15px;
}

.prodInner .btn_3 {
  display: inline-block;
  margin: 20px 0;
  border-radius: 8px;
  padding: 15px 30px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #001e60;
  color: white;
  font-size: 2vmin;
}

.prodInner .stats {
  width: 100%;
}

.prodInner .swiper-container {
  width: 50%;
}

.prodInner .swiper-container .swiper-slide img {
  width: 100%;
}

.prodInner .swiper-container .swiper-button-next, .prodInner .swiper-container .swiper-button-prev {
  color: #001e60;
}

@media only screen and (max-width: 812px) {
  /* body {
      background-color: lightblue;
    }   */
  /* .menu-filtro{

    } */
  h2 {
    font-size: 1.2rem;
  }
  .grid-productos .swiper-container {
    width: 80%;
  }
  .producto {
    margin: 0;
  }
  .producto .swiper-container {
    height: 30%;
  }
  .producto .wp {
    padding: 0.5rem;
  }
  .producto h4 {
    font-size: 1rem;
    height: 2rem;
  }
  .producto .img {
    height: 7rem;
  }
  .producto .count {
    padding: 0.5rem 0;
  }
  .producto .count span {
    font-size: 0.8rem;
    padding: 0.5rem;
  }
  .producto .count .cont_circ img {
    height: 1rem;
  }
  .producto .precio {
    padding: 0.5rem 0;
    font-size: 1rem;
  }
  .producto .btn {
    font-size: 0.5rem;
  }
  .producto .btn img {
    height: 0.5rem;
  }
  .menu-filter-ico {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 1rem;
  }
  .menu-filter-ico .item {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menu-filter-ico .item span {
    width: 70%;
    font-size: 0.8rem;
  }
  .menu-filter-ico .item img {
    height: auto;
    width: 1.5rem;
  }
  .cats {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 0.5rem;
  }
  .cats li {
    width: 100%;
    text-align: center;
  }
  .cats li a {
    width: 3rem;
    height: 3rem;
    /* padding: 1rem; */
  }
  .cats li a img {
    width: 100px;
    height: auto;
    max-width: none;
    max-height: none;
    min-width: auto;
    min-height: auto;
  }
  .cats li span {
    font-size: 0.7rem;
  }
  .categorias-home .cats {
    margin: 1rem 0 !important;
  }
  .grid-productos-int {
    grid-template-columns: 1fr 1fr;
  }
  .prodInner {
    padding: 2rem 0;
    display: block;
  }
  .prodInner .img {
    width: 100%;
  }
  .prodInner .swiper-container {
    width: 100%;
  }
  .prodInner .textos {
    width: 80%;
    margin: 0 auto;
  }
  .prodInner .textos form {
    width: 100%;
  }
  .prodInner .textos .stats {
    width: 100%;
  }
  .prodInner .textos h2, .prodInner .textos h3 {
    text-align: center;
  }
  .prodInner .textos ul {
    text-align: center;
  }
  .prodInner h2 {
    font-size: 1.5rem;
  }
  .prodInner h3 {
    font-size: 1.5rem;
  }
  .prodInner .btn_3 {
    font-size: 1.3rem;
  }
}

.d-lice-local-food header .logo img {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

header {
  width: 100%;
  background-color: #001e60;
  position: relative;
  z-index: 99;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

header .col {
  width: 70%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

header form {
  width: 60%;
  border: 1px solid white;
  display: flex;
  margin: 0 5%;
  justify-content: space-around;
  align-items: center;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}

header form input {
  background-color: transparent;
  border: 0;
}

header form input[type="search"] {
  width: 90%;
  font-size: 1vw;
  padding: 0.5vw;
  color: white;
}

header form input[type="search"]::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1;
  /* Firefox */
  text-transform: uppercase;
}

header form input[type="search"]:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
  text-transform: uppercase;
}

header form input[type="search"]::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
  text-transform: uppercase;
}

header form input[type="image"] {
  height: 1vw;
}

header .logo {
  /* position: absolute;
    top: 0px;
    left: 20px; */
  display: inline-block;
  width: 192px;
}

header .logo img {
  width: 100%;
}

header .menu-mobile {
  display: none;
  position: absolute;
  top: 28px;
  left: 20px;
  width: 45px;
}

header .menu-mobile img {
  width: 100%;
}

header .menu-open-dos {
  display: block !important;
}

header .icons-header .icon-header {
  position: relative;
  width: 35px;
  float: left;
  margin-left: 14px;
  cursor: pointer;
}

header .icons-header .icon-header img {
  width: 100%;
}

header .icons-header .icon-header .cart {
  width: 125%;
  position: relative;
  top: -10px;
}

header .icons-header .icon-header .badge {
  position: absolute;
  top: -5px;
  left: 29px;
  z-index: 1;
  color: #001e60;
}

header .icons-header .usuario-tool .user-menu {
  font-family: "Quicksand", sans-serif;
  position: absolute;
  top: 45px;
  right: 20px;
  background: white;
  padding: 10px;
  border-radius: 10px;
  width: 150px;
  display: none;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.3);
}

header .icons-header .usuario-tool .user-menu .item-user-menu {
  position: relative;
  padding: 10px 0;
}

header .icons-header .usuario-tool .user-menu .item-user-menu img {
  width: 12px;
  margin-right: 10px;
}

header .icons-header .usuario-tool .user-menu .item-user-menu:hover {
  color: #001e60;
}

header .icons-header .usuario-tool .user-menu .item-user-line {
  background: gainsboro;
  height: 1px;
}

header .icons-header .arrow-left {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 28px solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 20px solid white;
  top: -10px;
  right: 0px;
}

header .icons-header .menu-open .user-menu {
  display: block;
}

.menu-filtro {
  background-color: #d3d3d3;
}

.menu-filtro ul {
  margin: 0;
  display: flex;
  justify-content: center;
}

.menu-filtro ul li a {
  color: #001e60;
  font-family: "Futura";
  font-weight: bold;
  display: inline-block;
  padding: 0.5vw 1vw;
}

div.container {
  margin: 0 auto;
  padding: 2.4em 3em;
  text-align: center;
}

div.container a {
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
  color: white;
  text-decoration: none;
  font-size: 20px;
  margin: 0 10px;
  padding: 10px 10px;
  position: relative;
  z-index: 0;
  cursor: pointer;
}

div.borderXwidth a:before {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 2px;
  content: '';
  background: #FFF;
  transition: all 0.3s;
  left: 0;
  top: 0;
}

div.borderXwidth a:after {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 2px;
  content: '';
  background: #FFF;
  transition: all 0.3s;
  right: 0;
  bottom: 0;
}

div.borderXwidth a:hover:before, div.borderXwidth a.active:before {
  opacity: 1;
  width: 100%;
}

div.borderXwidth a:hover:after, div.borderXwidth a.active:after {
  opacity: 1;
  width: 100%;
}

.ancla-y-viento header .icons-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ancla-y-viento header .icons-header .icon-header {
  width: auto;
  float: none;
}

.ancla-y-viento header .icons-header .icon-header > img {
  width: 2.5vw;
}

.ancla-y-viento header .icons-header .icon-header > a {
  display: inline-block;
}

.ancla-y-viento header .icons-header .icon-header > a img {
  width: 2.5vw;
  top: inherit;
}

.ancla-y-viento header .icons-header .icon-header > a .badge {
  width: 1vw;
  height: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #001e60;
  font-size: 0.8vw;
  background-color: white;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.ancla-y-viento header .container {
  position: relative;
}

.ancla-y-viento header .container .social {
  /* position: absolute;
        top: 105%;
        right: 0; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ancla-y-viento header .container .social a {
  background-color: #001e60;
  padding: 0.5vw;
  width: 1vw;
  height: 1vw;
  margin: 0 0.5vw;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ancla-y-viento header .container .social a::before, .ancla-y-viento header .container .social a::after {
  display: none;
}

.ancla-y-viento header .container .social a img {
  width: 1.5vw;
}

@media (max-width: 91em) {
  body {
    background-color: none;
  }
}

@media (max-width: 70em) {
  body {
    background-color: none;
  }
}

@media (max-width: 64em) {
  body {
    background-color: none;
  }
}

@media (max-width: 50em) {
  body {
    background-color: none;
  }
}

@media (max-width: 45em) {
  body {
    background-color: none;
  }
  header .logo {
    max-width: 23%;
    left: 21%;
  }
  header .col {
    display: inline-block;
    text-align: right;
    padding: 0.5rem 1rem;
    /* form{
        margin-bottom: 1rem;
      } */
  }
  header .col .social, header .col .icons-header, header .col form {
    padding: 0.55rem 0;
  }
  header .col .social a img {
    height: 1.5rem;
  }
  header .col .icons-header {
    justify-content: flex-end;
  }
  header .col .icons-header .icon-header {
    margin-left: 0.2rem;
  }
  header .col .icons-header img {
    width: auto;
    height: 1.5rem;
  }
  header form {
    width: 100%;
    margin: 0;
    /* margin-left: 10%; */
  }
  header form input[type="search"] {
    font-size: 0.6rem;
  }
  header form input[type="image"] {
    height: 1rem;
  }
  .menue {
    display: none;
  }
  /* .menu-mobile {
    display: block !important;
  } */
  .menue {
    width: 100% !important;
    padding: 10px !important;
    box-sizing: border-box;
    position: relative;
    top: 100px;
    background: white;
  }
  .menue a {
    width: 100%;
    display: block;
    margin: 0 !important;
    box-sizing: border-box;
    color: #001e60 !important;
    border-bottom: 1px solid #001e60;
  }
  .ancla-y-viento header .icons-header .icon-header > img {
    width: 2rem;
  }
  .ancla-y-viento header .icons-header .icon-header > a img {
    width: 2rem;
  }
  .ancla-y-viento header .icons-header .icon-header > a .badge {
    width: 1rem;
    height: 1rem;
    font-size: 1rem;
  }
  .menu-filtro ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    display: none;
    gap: 0.5rem 0;
  }
  .menu-filtro ul li {
    display: inline-block;
    text-align: center;
  }
  .menu-filtro ul li a {
    width: 100%;
    font-size: 0.8rem;
    display: inline-block;
    padding: 0.2rem 0.5rem;
  }
}

@media (max-width: 43em) and (orientation: landscape) {
  body {
    background-color: none;
  }
}

.d-lice-local-food .full_banner .mitad img {
  width: 51%;
}

.d-lice-local-food .wrap_page h1 {
  display: none;
}

.full_banner {
  display: inline-block;
  width: 100%;
  padding-bottom: 21px;
  text-align: center;
  border-bottom: 4px solid #001e60;
}

.full_banner .mitad {
  display: inline-block;
  width: 40%;
  vertical-align: middle;
}

.full_banner .mitad h1 {
  font-size: 3vw;
  color: #001e60;
  width: 75%;
  text-align: center;
  display: inline-block;
}

.full_banner .mitad img {
  display: inline-block;
  width: 80%;
}

.wrap_page {
  margin: 0 auto;
  width: 60%;
  flex: 1;
}

.wrap_page .agregar {
  cursor: pointer;
}

.wrap_page .dir {
  display: inline-block;
  width: 100%;
}

.wrap_page .dir p {
  color: #6b6b6b;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  text-transform: uppercase;
  /*font-size: 1.9vw;*/
  letter-spacing: 3px;
  font-weight: bold;
}

.wrap_page h1, .wrap_page h2, .wrap_page h3 {
  color: #001e60;
  text-align: center;
  /* margin-top: 40px; */
  font-size: 1.4vw;
  text-transform: uppercase;
}

.wrap_page h3 {
  font-size: 0.9vw;
}

.wrap_page .img_tienda {
  max-width: 300px;
  text-align: center;
  margin: 0 auto;
  display: block;
}

.wrap_page .buscador {
  margin: 30px auto 0;
  width: 40%;
  text-align: center;
}

.wrap_page .buscador form {
  width: 100%;
  display: inline-block;
}

.wrap_page .buscador .form-control {
  display: inline-block;
  width: calc(100% - 30px);
  border: solid 1px #6b6b6b;
}

.wrap_page .cont_tiendas {
  /* width: 100%;
    display: flex;
    text-align: center;
    justify-content: center; */
}

.wrap_page .cont_tiendas .tienda {
  width: 20%;
}

.wrap_page .cont_tiendas .tienda .list-content {
  background-color: #fff;
  width: 100%;
}

.wrap_page .cont_tiendas .tienda .list-content h2, .wrap_page .cont_tiendas .tienda .list-content img {
  width: calc(100% - 20px);
  background-color: #0080b6;
  padding: 30px 10px;
  text-align: center;
  color: #6d742f;
  text-transform: uppercase;
  font-size: 0.7vw;
  font-family: "Roboto", sans-serif;
  font-style: italic;
  flex: 1 0 auto;
  word-wrap: break-word;
  border-radius: 20px 20px 0 0;
  -webkit-border-radius: 20px 20px 0 0;
  -moz-border-radius: 20px 20px 0 0;
}

.wrap_page .cont_tiendas .tienda .list-content img {
  background-color: transparent;
  flex: none;
  height: auto;
  width: auto;
}

.wrap_page .cont_tiendas .tienda .list-content a {
  background-color: #001e60;
  color: white;
  width: 100%;
  text-align: center;
  padding: 4px 0;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  display: inline-block;
  text-decoration: none;
  font-size: 0.6vw;
}

.wrap_page .cont_tiendas .tienda .list-content .cont_img {
  flex: 1 0 auto;
  text-align: center;
}

.wrap_page .cont_tiendas .tienda .list-content .cont_img img {
  display: inline-block;
  max-width: 80%;
  max-height: 235px;
}

.wrap_page .cont_tiendas .tienda .list-content h4, .wrap_page .cont_tiendas .tienda .list-content .opts {
  display: inline-block;
  width: 100%;
}

.wrap_page .cont_tiendas .tienda .list-content h4 {
  font-size: 0.9vw;
  color: #909090;
  text-align: center;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0;
  flex: 1 0 auto;
  margin: 10px 0;
  font-weight: bold;
}

.wrap_page .cont_tiendas .tienda .list-content .opts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
}

.wrap_page .cont_tiendas .tienda .list-content .opts p {
  flex: 1 0 auto;
  font-size: 0.8vw;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.wrap_page .cont_tiendas .tienda .list-content .opts .cart_opt {
  flex: 1 0 auto;
  text-align: right;
  width: 50%;
}

.wrap_page .cont_tiendas .tienda .list-content .opts .cart_opt span {
  width: 20px;
  color: #6b6b6b;
  font-size: 0.8vw;
  margin: 0 7px;
  vertical-align: super;
}

.wrap_page .cont_tiendas .tienda .list-content .opts .cart_opt .cont_circ {
  display: inline-block;
  width: 20%;
  cursor: pointer;
}

.wrap_page .cont_tiendas .tienda .list-content .opts .cart_opt .cont_circ img {
  display: inline-block;
  width: 100%;
  padding: 0;
}

/*style ancla y viento*/
.categorias-home .cats {
  margin-top: -1.5vw;
}

.grid-productos {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-bottom: 1vw;
  position: relative;
}

.grid-productos .swiper-button-prev, .grid-productos .swiper-button-next {
  color: #001e60;
}

.grid-productos .swiper-button-prev {
  left: inherit;
  right: 105%;
}

.grid-productos .swiper-button-next {
  right: inherit;
  left: 105%;
}

.ancla-y-viento {
  /* h2{
    color: $terciario;
    font-family: $bebas;
    font-family: 'Futura Hv BT';
    font-weight: bold;
    letter-spacing: 0;
    font-size: 1.7vw;
    margin-bottom: 2vw;
  } */
}

.ancla-y-viento .full_banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0;
}

.ancla-y-viento .full_banner .mitad:nth-of-type(1) {
  width: 30%;
}

.ancla-y-viento .full_banner .mitad:nth-of-type(2) {
  width: 70%;
}

.ancla-y-viento .wrap_page {
  /*.cont_tiendas{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 1vw 1vw;
      .tienda{
        width: 100%;
        .list-content{
          padding: 1vw;
        }
      }
    }*/
}

.ancla-y-viento .wrap_page .cats {
  margin-top: inherit;
}

.ancla-y-viento .wrap_page .cats li a span {
  font-family: "Futura";
}

.ancla-y-viento .wrap_page .cats li a:hover span {
  color: #6d742f;
}

.ancla-y-viento .wrap_page .cont_tiendas .tienda .list-content {
  /* background-color: $gris_claro; */
  border: 1px solid #001e60;
  padding: 1vw;
}

.ancla-y-viento .wrap_page .cont_tiendas .tienda .list-content a {
  /* background-color: $cuartario; */
  background-color: #001e60;
  border: 0;
  font-family: "Futura";
  font-weight: bold;
  padding: 0.5vw 0;
  font-size: 0.8vw;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.ancla-y-viento .wrap_page .cont_tiendas .tienda .list-content h3, .ancla-y-viento .wrap_page .cont_tiendas .tienda .list-content h4 {
  text-align: left;
  margin: 0;
  letter-spacing: 0;
}

.ancla-y-viento .wrap_page .cont_tiendas .tienda .list-content h4 {
  margin-bottom: 0.7vw;
}

.ancla-y-viento .wrap_page .cont_tiendas .tienda .list-content h3 {
  color: #001e60;
}

.ancla-y-viento .wrap_page .cont_tiendas .tienda .list-content h3:nth-of-type(2) {
  margin-bottom: 0.7vw;
}

.ancla-y-viento .wrap_page .cont_tiendas .tienda .list-content h3 {
  color: #6b6b6b;
}

.ancla-y-viento .wrap_page .cont_tiendas .tienda .list-content .cont_img {
  height: 11vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ancla-y-viento .wrap_page .cont_tiendas .tienda .list-content .cont_img img {
  max-width: 80%;
  max-height: 80%;
}

.ancla-y-viento .wrap_page .cont_tiendas .tienda .list-content .opts {
  align-items: center;
  margin-bottom: 0.7vw;
}

.ancla-y-viento .wrap_page .cont_tiendas .tienda .list-content .opts p {
  color: black;
  font-family: "Futura";
  font-weight: bold;
  font-size: 1vw;
  text-align: left;
}

.ancla-y-viento .list-pagination {
  text-align: center;
}

/* .swiper-container {
  width: 100%;
  height: 100%;
} */
/* .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
} */
.banner-home .swiper-container .swiper-slide a {
  width: 100%;
}

.banner-home .swiper-container .swiper-slide img {
  width: 100%;
}

.banner-home .swiper-button-prev, .banner-home .swiper-button-next {
  color: #001e60;
  color: white;
}

.deco-f {
  width: 10%;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.deco-f.left {
  left: 0;
}

.deco-f.right {
  right: 0;
}

#snackbar {
  visibility: hidden;
  width: 250px;
  background-color: white;
  border: solid 1px #001e60;
  color: #001e60;
  text-align: center;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: calc(50% - 141px);
  bottom: 30px;
  font-size: 17px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

.list-pagination {
  display: inline-block;
  width: 100%;
  text-align: right;
  margin: 21px 0;
}

.list-pagination .pagination {
  display: inline-block;
  text-align: right;
}

.list-pagination .pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-size: 0.7vw;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}

.list-pagination .pagination a.active {
  border: 1px solid #001e60;
  color: #001e60;
}

.list-pagination .pagination a:hover:not(.active) {
  background-color: #001e60;
}

.list-pagination .pagination a.arrow {
  font-weight: bold;
  font-size: 0.9vw;
  font-family: "Quicksand", sans-serif;
  padding: 8px 4px;
  line-height: 0.6vw;
}

@media (max-width: 70em) {
  .list-pagination .pagination a {
    font-size: 1.2vw;
  }
  .list-pagination .pagination a.arrow {
    font-size: 1.2vw;
  }
}

@media (max-width: 50em) {
  .list-pagination .pagination a {
    font-size: 1.6vw;
  }
  .list-pagination .pagination a.arrow {
    font-size: 1.6vw;
  }
}

@media (max-width: 45em) {
  .list-pagination .pagination a {
    font-size: 2.8vw;
  }
  .list-pagination .pagination a.arrow {
    font-size: 2.8vw;
  }
}

@media (max-width: 43em) and (orientation: landscape) {
  .list-pagination .pagination a {
    font-size: 2.2vw;
  }
  .list-pagination .pagination a.arrow {
    font-size: 2.2vw;
  }
}

@media (max-width: 91em) {
  body {
    background-color: none;
  }
  .wrap_page {
    width: 80%;
  }
  .wrap_page .cont_tiendas .tienda {
    width: 23%;
    margin: 15px 1%;
  }
  .wrap_page .cont_tiendas .tienda .list-content h4 {
    font-size: 1.4vw;
  }
  .wrap_page .cont_tiendas .tienda .list-content h2 {
    font-size: 1.2vw;
  }
  .wrap_page .cont_tiendas .tienda .list-content .opts p {
    font-size: 1.2vw;
  }
  .wrap_page .cont_tiendas .tienda .list-content .opts .cart_opt span {
    font-size: 1.1vw;
  }
  .wrap_page .cont_tiendas .tienda .list-content .opts .cart_opt .cont_circ {
    width: 14%;
  }
  .wrap_page .cats li a span {
    font-size: 1.2vw;
  }
}

@media (max-width: 70em) {
  body {
    background-color: none;
  }
  .wrap_page {
    width: 85%;
  }
  .wrap_page h1 {
    font-size: 2.3vw;
  }
  .wrap_page .buscador .form-control {
    font-size: 1.7vw;
  }
  .wrap_page .cont_tiendas .tienda .list-content h2 {
    font-size: 1.5vw;
  }
  .wrap_page .cont_tiendas .tienda .list-content a {
    font-size: 1.1vw;
    padding: 9px 0;
  }
  .wrap_page .cats li a span {
    font-size: 1.2vw;
  }
}

@media (max-width: 64em) {
  body {
    background-color: none;
  }
  .full_banner .mitad {
    width: 100%;
    margin-bottom: 15px;
  }
  .wrap_page {
    width: 90%;
  }
  .wrap_page h2 {
    font-size: 2vw;
  }
  .wrap_page h3 {
    font-size: 1.8vw;
  }
  .wrap_page .cont_tiendas .tienda .list-content .cont_img img {
    max-height: 200px;
    width: auto;
  }
  .wrap_page .cont_tiendas .tienda .list-content h4 {
    font-size: 1.8vw;
  }
  .wrap_page .cont_tiendas .tienda .list-content .opts p {
    font-size: 1.5vw;
  }
  .wrap_page .cont_tiendas .tienda .list-content .opts .cart_opt span {
    font-size: 1.5vw;
  }
  .wrap_page .cats li a span {
    font-size: 1.4vw;
  }
}

@media (max-width: 50em) {
  body {
    background-color: none;
  }
  .wrap_page .cats li, .wrap_page .cats li.ancla-y-viento {
    width: 30%;
  }
  .wrap_page .cats li a span, .wrap_page .cats li.ancla-y-viento a span {
    font-size: 2.4vw;
  }
  .wrap_page h1 {
    font-size: 2.9vw;
  }
  .wrap_page .buscador .form-control {
    font-size: 2.3vw;
  }
  .wrap_page .cont_tiendas .tienda {
    width: 48%;
  }
  .wrap_page .cont_tiendas .tienda .list-content h2 {
    font-size: 2.4vw;
  }
  .wrap_page .cont_tiendas .tienda .list-content h4 {
    font-size: 2.4vw;
  }
  .wrap_page .cont_tiendas .tienda .list-content a {
    font-size: 1.8vw;
    padding: 16px 0;
  }
  .wrap_page .cont_tiendas .tienda .list-content .opts p {
    font-size: 2vw;
  }
  .wrap_page .cont_tiendas .tienda .list-content .opts .cart_opt span {
    font-size: 2vw;
  }
}

@media (max-width: 45em) {
  body {
    background-color: none;
  }
  .ancla-y-viento .full_banner .mitad:nth-of-type(1), .ancla-y-viento .full_banner .mitad:nth-of-type(2) {
    width: 100%;
  }
  .full_banner .mitad h1 {
    font-size: 6vw;
  }
  .wrap_page {
    width: 95%;
    width: 90%;
  }
  .wrap_page .cats {
    margin-top: 0;
  }
  .wrap_page .cats li, .wrap_page .cats li.ancla-y-viento {
    width: 47%;
  }
  .wrap_page .cats li a span, .wrap_page .cats li.ancla-y-viento a span {
    font-size: 2.8vw;
  }
  .wrap_page h1 {
    font-size: 5.2vw;
  }
  .wrap_page h2 {
    font-size: 4vw;
  }
  .wrap_page h3 {
    font-size: 3.6vw;
  }
  .wrap_page .buscador {
    width: 70%;
  }
  .wrap_page .buscador .form-control {
    font-size: 3.4vw;
  }
  .wrap_page .cont_tiendas .tienda {
    width: 100%;
    margin: 15px 0;
  }
  .wrap_page .cont_tiendas .tienda .list-content h4 {
    font-size: 5.2vw;
  }
  .wrap_page .cont_tiendas .tienda .list-content h2 {
    font-size: 3.2vw;
  }
  .wrap_page .cont_tiendas .tienda .list-content a {
    font-size: 2.8vw;
    padding: 19px 0;
  }
  .wrap_page .cont_tiendas .tienda .list-content .opts p {
    font-size: 5vw;
  }
  .wrap_page .cont_tiendas .tienda .list-content .opts .cart_opt span {
    font-size: 4vw;
  }
  .ancla-y-viento .wrap_page .cont_tiendas .tienda .list-content .opts p {
    font-size: 4vw;
  }
  .ancla-y-viento .wrap_page .cont_tiendas .tienda .list-content {
    padding: 1rem;
  }
  .ancla-y-viento .wrap_page .cont_tiendas .tienda .list-content .cont_img {
    height: 30vh;
  }
  .ancla-y-viento .wrap_page .cont_tiendas .tienda .list-content a {
    font-size: 1rem;
  }
  .deco-f {
    display: none;
  }
  h2 {
    margin: 1.2rem 0;
  }
  .grid-productos .swiper-button-next::after, .grid-productos .swiper-button-prev::after {
    font-size: 1.5rem;
  }
  .grid-productos .swiper-button-next {
    left: inherit;
    right: 0;
  }
  .grid-productos .swiper-button-prev {
    left: 0;
    right: inherit;
  }
}

@media (max-width: 43em) and (orientation: landscape) {
  body {
    background-color: none;
  }
}

.d-lice-local-food .wrap_foot .third.ini .logo img {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

footer {
  width: 100%;
  background-color: #001e60;
  box-sizing: border-box;
  overflow: hidden;
}

footer .wrap_foot {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

footer .wrap_foot .third {
  width: 30%;
}

footer .wrap_foot .third .social {
  display: inline-block;
  width: 100%;
  text-align: center;
}

footer .wrap_foot .third .social a {
  display: inline-block;
  margin: 0;
}

footer .wrap_foot .third .social a img {
  width: 70%;
}

footer .wrap_foot .third .inf p {
  margin-top: 15px;
  color: white;
  text-align: center;
}

footer .wrap_foot .third.ini {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

footer .wrap_foot .third.ini .logo {
  width: 40%;
}

footer .wrap_foot .third.ini .logo a, footer .wrap_foot .third.ini .logo img {
  display: inline-block;
  width: 100%;
}

footer .wrap_foot .third.ini .links a {
  display: inline-block;
  width: 100%;
  color: white;
  margin: 10px;
}

footer .wrap_foot .third.fin {
  align-content: flex-end;
  text-align: right;
}

@media (max-width: 91em) {
  footer .wrap_foot {
    width: 90%;
  }
}

@media (max-width: 70em) {
  footer .wrap_foot {
    width: 100%;
  }
  footer .wrap_foot .third {
    width: 32%;
  }
  footer .wrap_foot .third .social a {
    width: 13%;
  }
  footer .wrap_foot .third .social a img {
    width: 65%;
  }
}

@media (max-width: 50em) {
  footer .wrap_foot .third {
    width: 30%;
  }
  footer .wrap_foot .third .logo {
    display: none;
  }
  footer .wrap_foot .third .social a {
    width: 25%;
  }
}

@media (max-width: 45em) {
  footer {
    text-align: center;
  }
  footer .wrap_foot {
    display: block;
  }
  footer .wrap_foot .third {
    width: 100%;
  }
}
