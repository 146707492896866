@media only screen and (max-width: 812px) {
    /* body {
      background-color: lightblue;
    }   */

    /* .menu-filtro{

    } */

    h2{
        font-size: 1.2rem;
    }

    .grid-productos{
        .swiper-container{
            width: 80%;
        }   
    }

    .producto{
        margin: 0;
        .swiper-container{
            height: 30%;
        }
        .wp{
            padding: 0.5rem;
        }
        h4{
            font-size: 1rem;
            height: 2rem;
        }
        .img{
            height: 7rem;
        }
        .count {
            padding: 0.5rem 0;
            span{
                font-size: 0.8rem;
                padding: 0.5rem;
            }
            .cont_circ {
                img{
                    height: 1rem;
                }
            }
        }
        .precio{
            padding: 0.5rem 0;
            font-size: 1rem;
        }
        .btn{
            font-size: 0.5rem;
            img{
                height: 0.5rem;
            }
        }
    }

    

    .menu-filter-ico{
        
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        gap: 1rem;
        .item {
            width: 80%;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            span{
                width: 70%;
                font-size: 0.8rem;
            }
            img{
                height: auto;
                width: 1.5rem;
            }
        }
    }


    .cats{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto;
        gap: 0.5rem;
        li{
            width: 100%;
            text-align: center;
            a{  
                width: 3rem;
                height: 3rem;
                /* padding: 1rem; */
                img{
                    width: 100px;
                    height: auto;
                    max-width: none;
                    max-height: none;
                    min-width: auto;
                    min-height: auto;
                }
            }
            span{
                font-size: 0.7rem;
            }
        }
    }   

    .categorias-home{
        .cats{
            margin: 1rem 0 !important;
        }
    }

    .grid-productos-int{
        grid-template-columns: 1fr 1fr;
    }

    .prodInner{
        padding: 2rem 0;
        display: block;
        .img{
          width: 100%;
        }
        .swiper-container{
            width: 100%;
        }
        .textos{
          width: 80%;
          margin: 0 auto;
          form{
            width: 100%;
          }
          .stats{
            width: 100%;
          }
          h2, h3{
            text-align: center;
          }
          ul{
              text-align: center;
          }
        }
        h2{
          font-size: 1.5rem;
        }
        h3{
          font-size: 1.5rem;  
        }
        .btn_3{
          font-size: 1.3rem;
        }
      }

  }