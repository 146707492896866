.d-lice-local-food{
  .wrap_foot{
    .third.ini .logo{
      img{
        @include border-radius(50%);
      }
    }
  }
}
footer{
  width: 100%;
  background-color: $principal;
  box-sizing: border-box;
  overflow: hidden;
  .wrap_foot{
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    .third{
      width: 30%;
      .social{
        display: inline-block;
        width: 100%;
        text-align: center;
        a{
          display: inline-block;
          margin: 0;
          img{
            width: 70%;
          }
        }
      }
      .inf{
        p{
          margin-top: 15px;
          color: white;
          text-align: center;
        }
      }
      &.ini{
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        .logo{
          width: 40%;
          a,img{
            display: inline-block;
            width: 100%;
          }
        }
        .links{
          a{
            display: inline-block;
            width: 100%;
            color: white;
            margin: 10px;
          }
        }
      }
      &.fin{
        align-content: flex-end;
        text-align: right;
      }
    }
  }
}
@import "breakpoints_footer";
