$principal : #001e60;
$secundario: #333333;
$terciario: #0080b6;
$cuartario: #6d742f;
$gris: #6b6b6b;
$gris_claro: #efefef;
$gris_claro_textos: #909090;

$color_1: rgba(241, 187, 82, 0.35);
$color_2: #6ef175;
$color_3: #59f1cf;
$color_4: #9fdaf1;
$color_5: #93f1ca;
$color_6: #9ef119;
$color_1: none;
$color_2: none;
$color_3: none;
$color_4: none;
$color_5: none;
$color_5: none;
$color_6: none;
$font_family_cuerpo: 'Roboto', sans-serif;
$font_family_titulos: 'Quicksand', sans-serif;
$futura: 'Futura';
$roboto: 'Roboto Cn';
$bebas: 'Bebas Neue';
$frutiger: 'FrutigerNeueLTCom';

/*
$principal : #3c647e;
$secundario: #fdfbd2;
$terciario: #fdfbd2;
$cuartario: #dc843c;
$gris: #6b6b6b;
$gris_claro: #c4c4c4;
$gris_claro_textos: #909090;
*/

$logo: '/img/back/logo_ancla.png';
$ancho_logo: 192px;
//ancla as
