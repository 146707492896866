@include breakpoint(desktop){

}
@include breakpoint(biglaptop) {
  footer{
    .wrap_foot{
      width: 90%;
    }
  }
}
@include breakpoint(laptop){
  footer{
    .wrap_foot{
      width: 100%;
      .third{
        width: 32%;
        .social{
          a{
            width: 13%;
            img{
              width: 65%;
            }
          }
        }
      }
    }
  }
}
@include breakpoint(tablet) {
}
@include breakpoint(phablet) {
  footer{
    .wrap_foot{
      .third{
        width: 30%;
        .logo{
          display: none;
        }
        .social{
          a{
            width: 25%;
          }
        }
      }
    }
  }

}
@include breakpoint(mobileonly) {

  footer{
    text-align: center;
    .wrap_foot {
      display: block;
      .third{
        width: 100%;
      }
    }
  }

}
@include breakpoint(mobileland){

}
