.d-lice-local-food{
  header{
    .logo{
      img{
        @include border-radius(50%);
      }
    }
  }
}

header {
  width: 100%;
  background-color: $principal;
  position: relative;
  z-index: 99;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .col{
    width: 70%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  form{
    width: 60%;
    border: 1px solid white;
    display: flex;
    margin: 0 5%;
    justify-content: space-around;
    align-items: center;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
    input{
      background-color: transparent;
      border: 0;
    }
    input[type="search"]{
      width: 90%;
      font-size: 1vw;
      padding: 0.5vw;
      color: white;
      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: white;
        opacity: 1; /* Firefox */
        text-transform: uppercase;
      }
      
      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: white;
        text-transform: uppercase;
      }
      
      &::-ms-input-placeholder { /* Microsoft Edge */
        color: white;
        text-transform: uppercase;
      }
    }
    input[type="image"]{
      height: 1vw;
    }
  }
  .logo{
    /* position: absolute;
    top: 0px;
    left: 20px; */
    display: inline-block;
    width: $ancho_logo;
    img{
      width: 100%;
    }
  }
  .menu-mobile {
    display: none;
    position: absolute;
    top: 28px;
    left: 20px;
    width: 45px;
    img {
      width: 100%;
    }
  }
  .menu-open-dos {
    display: block !important;
  }
  .icons-header {
    .icon-header {
      position: relative;
      width: 35px;
      float: left;
      margin-left: 14px;
      cursor: pointer;
      img {
        width: 100%;
      }
      .cart {
        width: 125%;
        position: relative;
        top: -10px;
      }
      .badge {
        position: absolute;
        top: -5px;
        left: 29px;
        z-index: 1;
        color: $principal;
      }
    }
    .usuario-tool {
      .user-menu {
        font-family: "Quicksand", sans-serif;
        position: absolute;
        top: 45px;
        right: 20px;
        background: white;
        padding: 10px;
        border-radius: 10px;
        width: 150px;
        display: none;
        box-shadow: 0 4px 5px rgba(0,0,0,0.3);
        .item-user-menu {
          position: relative;
          padding: 10px 0;
          img {
            width: 12px;
            margin-right: 10px;
          }
          &:hover {
            color: $principal;
          }
        }
        .item-user-line {
          background: gainsboro;
          height: 1px;
        }
      }

    }
    .arrow-left {
      position: absolute;
      width: 0;
      height: 0;
      border-left: 28px solid transparent;
      border-right: 0px solid transparent;
      border-bottom: 20px solid white;
      top: -10px;
      right: 0px;
    }
    .menu-open {
      .user-menu {
        display: block;
      }
    }
  }
}
.menu-filtro{
  background-color: #d3d3d3;
  ul{
    margin: 0;
    display: flex;
    justify-content: center;

    li{
      a{
        color: $principal;
        font-family: $futura;
        font-weight: bold;
        display: inline-block;
        padding: 0.5vw 1vw; 
      }
    }
  }
}
div.container {
  margin: 0 auto;
  padding: 2.4em 3em;
  text-align: center;
  a {
    text-transform: uppercase;
    font-family: $font_family_cuerpo;
    letter-spacing: 2px;
    color: white;
    text-decoration: none;
    font-size: 20px;
    margin: 0 10px;
    padding: 10px 10px;
    position: relative;
    z-index: 0;
    cursor: pointer;
  }
}
div.borderXwidth {
  a {
    &:before {
      position: absolute;
      opacity: 0;
      width: 0;
      height: 2px;
      content: '';
      background: #FFF;
      transition: all 0.3s;
      left: 0;
      top: 0;
    }
    &:after {
      position: absolute;
      opacity: 0;
      width: 0;
      height: 2px;
      content: '';
      background: #FFF;
      transition: all 0.3s;
      right: 0;
      bottom: 0;
    }
    &:hover, &.active {
      &:before {
        opacity: 1;
        width: 100%;
      }
      &:after {
        opacity: 1;
        width: 100%;
      }
    }
  }
}

.ancla-y-viento{

  header{
    .icons-header {
      display: flex;
      align-items: center;  
      justify-content: center;
      .icon-header{
          width: auto;
          float: none;
          & > img{
              width: 2.5vw;
          }
          & > a {
              display: inline-block;
              img{
                  width: 2.5vw;
                  top: inherit;
              }
              .badge{
                width: 1vw;
                height: 1vw;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $principal;
                font-size: 0.8vw;
                background-color: white;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
              }
          }

      }
    }
    .container{
      position: relative;
      .social{
        /* position: absolute;
        top: 105%;
        right: 0; */
        
        display: flex;
        justify-content: space-between;
        align-items: center;
        a{
          background-color: $principal;
          padding: 0.5vw;
          width: 1vw;
          height: 1vw;
          margin: 0 0.5vw;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          &::before, &::after{
            display: none;
          }
          img{
            width: 1.5vw; 
          }
        }
      }
    }
  }

}

@import "breakpoints_menu";
