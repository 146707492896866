.list-pagination{
  display: inline-block;
  width: 100%;
  text-align: right;
  margin: 21px 0;
  .pagination {
    display: inline-block;
    text-align: right;
    a {
      color: black;
      float: left;
      padding: 8px 16px;
      text-decoration: none;
      font-family: $font_family_cuerpo;
      font-size: 0.7vw;
      @include border-radius(10px);
      &.active {
        border: 1px solid $principal;
        color: $principal;
      }
      &:hover:not(.active) {
        background-color: $principal;
      }
      &.arrow{
        font-weight: bold;
        font-size: 0.9vw;
        font-family: $font_family_titulos;
        padding: 8px 4px;
        line-height: 0.6vw;
      }
    }
  }
}

@import "breakpoints_pagination";
