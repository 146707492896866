@include breakpoint(desktop){

}
@include breakpoint(biglaptop) {
}
@include breakpoint(laptop){
  .list-pagination .pagination a{
    font-size: 1.2vw;
    &.arrow{
      font-size: 1.2vw;
    }
  }
}
@include breakpoint(tablet) {
}
@include breakpoint(phablet) {
  .list-pagination .pagination a{
    font-size: 1.6vw;
    &.arrow{
      font-size: 1.6vw;
    }
  }

}
@include breakpoint(mobileonly) {
  .list-pagination .pagination a{
    font-size: 2.8vw;
    &.arrow{
      font-size: 2.8vw;
    }
  }

}
@include breakpoint(mobileland){
  .list-pagination .pagination a{
    font-size: 2.2vw;
    &.arrow{
      font-size: 2.2vw;
    }
  }
}
